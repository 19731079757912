import React, { Component } from "react";
import { GoogleLogin } from "react-google-login";
//import { config } from "../config";

const config = require('../config.js');

const helper = require("../helper");

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
    };
  }

  checkLogin() {
    this.setState({ isLoading: true });

    helper
      .postToServer("/login", {
        user: document.getElementById("username").value,
        password: document.getElementById("password").value,
      })
      .then((res) => {
        this.setState({ isLoading: false });
        if (res.status == "ok") {
          sessionStorage.setItem("uid", res.uid);
//          alert(JSON.stringify(res.roles));
          if (res.roles) {
            for (var i = 0; i < res.roles.length; i++)
            {
              sessionStorage.setItem(res.roles[i], "true");
              
            }
          }

//          document.location = "/home";
if (sessionStorage.getItem("rd"))
//      history.replace(sessionStorage.getItem("rd"))
      document.location = sessionStorage.getItem("rd");
    else
      document.location = "/home"

        } else {
          alert(res.result);
        }
      });
  }

  responseGoogle(response) {
    this.setState({ isLoading: true });

    helper.postToServer("/logingoogle", response).then((res) => {
      this.setState({ isLoading: false });
      if (res.status == "ok") {
        sessionStorage.setItem("uid", res.uid);
        if (res.roles) {
          for (var i = 0; i < res.roles.length; i++)
          {
            sessionStorage.setItem(res.roles[i], "true");
            
          }
        }



//        document.location = "/home";
if (sessionStorage.getItem("rd"))
//      history.replace(sessionStorage.getItem("rd"))
      document.location = sessionStorage.getItem("rd");
    else
      document.location = "/home"


      } else {
        alert("Something went wrong");
      }
    });
  }

  componentDidMount() {}
  render() {
    let secure;
    if (!window.location.origin.startsWith("https://"))
      secure = <a href="https://localhost:3010/login">Secure</a>;

    if (!this.state.isLoading) {
      return (
        <div className="App" style={{ margin: "30px" }}>
          {secure}
          <br />
          <br />
          Username:
          <br /> <input type="text" id="username" autoFocus />
          <br />
          <br />
          Password:
          <br /> <input type="password" id="password" />
          <br />
          <br />
          <input
            type="button"
            value="Login"
            onClick={this.checkLogin.bind(this)}
          />
          <br />
          <br />
          <GoogleLogin
            clientId="434226889324-egosknqj12qeas15k2gqhas3c16ufsnp.apps.googleusercontent.com"
            onSuccess={this.responseGoogle.bind(this)}
            onFailure={(res) => {
              alert("Error with Google Login:\n\n" + res.details);
            }}
          />
        </div>
      );
    } else {
      return (
        <div className="App" style={{ margin: "30px" }}>
          <i
            className="fa fa-spinner fa-spin fa-5x"
            style={{ color: "#54bbff" }}
          />
        </div>
      );
    }
  }
}

export default Login;
