import React from "react";
//import sign1 from "../images/tree2.jpg";

const helper = require("../../helper");
const misc = require("../omwui/misc");

const g_link = "/user/diags";

const gridContainer = {
  display: "grid",
  gridTemplateColumns: "auto auto auto auto",
  backgroundColor: "#2196F3",
  padding: "5px",
}

const gridItem = {
  backgroundColor: "rgba(255, 255, 255, 255)",
  border: "1px solid rgba(0, 0, 0, 0.8)",
  padding: "5px",
  fontSize: "10px",
  textAlign: "center",
}

const gridItemr = {
  backgroundColor: "rgb(255,0,0)",
  border: "1px solid rgba(0, 0, 0, 0.8)",
  padding: "5px",
  fontSize: "10px",
  textAlign: "center"
}


class GridEditor extends React.Component {

    q = helper.parseQuery();
    id = this.q.id ? this.q.id : 1;
    state = {
      grid: null,
    }

componentDidMount()  {  

//        document.body.addEventListener('click', this.clickHandler );

        helper.getFromServer(g_link+`?dw=get1&id=${this.id}`)
        .then(res => {
          var mg = document.getElementById("main_grid");
          var grid = res.result;
          console.log("grid", grid)
          var s = "";
          for (var i = 0; i < grid.width; i++)
          {
            s += "auto ";
          }
          
//          console.log(s);

          mg.style.gridTemplateColumns = s;
          this.setState({grid: grid});
        }).catch( err =>
        {
          console.log(err);
        })
      }

render()
{

    var grid = this.state.grid;
    if (!grid)
    {
      grid = {};
    }

    var grid1 = [];
    var i = 0;
    for (var y = 0; y < grid.width; y++)
    {
      console.log("width!");

      for (var x = 0; x < grid.height; x++)
      {
        var cname = gridItem;
        var icons = [];
        i++;
        grid1.push(<div style={cname} key={i} id={`gi${i}`} onClick={(e) => {

          var gi = document.getElementById(e.target.id);
          if (!gi)
          {
            console.log(e.target)
            return;
          }
          const type = document.getElementById("abject").value;
          var which = e.target.id.substring(2)-1;

/*          if (thelper.mam.createGridElement(type, document, gi, this.state.grid.layout, which))
            return; */
/*            
          if (type === "tree")
          {
            var elem = document.createElement("img");
            elem.setAttribute("src", tree1);
            elem.setAttribute("width", "20");
            gi.appendChild(elem)
            this.state.grid.layout[which] = 5;
            return;

          }
          else if (type === "sign")
          {
            var elem = document.createElement("img");
            elem.setAttribute("src", sign1);
            elem.setAttribute("width", "20");
            gi.appendChild(elem)
            this.state.grid.layout[which] = 6;
            return;

          }
          else if (type === "bridge")
          {
            var elem = document.createElement("img");
            elem.setAttribute("src", sign1);
            elem.setAttribute("width", "20");
            gi.appendChild(elem)
            this.state.grid.layout[which] = 7;
            return;

          }

          else if (type === "bridgeDoor")
          {
            var elem = document.createElement("img");
            elem.setAttribute("src", door1);
            elem.setAttribute("width", "20");
            gi.appendChild(elem)
            this.state.grid.layout[which] = 8;
            return;

          }
*/          
          if (gi.childNodes.length > 1)
          {
            gi.removeChild(gi.childNodes[1]);
          }
//          document.getElementById("placehere").appendChild(elem);

//          console.log(gi.style.backgroundColor);
/*          var row = Math.floor(which/grid.width);
          var col = (which % grid.width);
          console.log(row, col); */

          if (grid.layout[which] == 0)
          {
            this.state.grid.layout[which] = 1;
            gi.style.backgroundColor = "#FF0000";
          }
          else
          {
            this.state.grid.layout[which] = 0;
            gi.style.backgroundColor = "#FFFFFF";
          }
  
//          console.log(e.target.id)
        }}>{i}{icons}</div>)

      }
    }

  return <>
  <button onClick={() => {
    helper.postToServer(g_link, {dw: "sg", layout: this.state.grid, id:this.id}).then(r => {
      alert("saved!");
    })
  }}>Save</button><misc.Space/> Object: <misc.Space/> 
  <select id="abject">
    <option value="track">Track</option>

    </select>

  <br/><br/>
   <div style={gridContainer} id="main_grid">
     {grid1}
</div> 
  </>;
}

}


export default GridEditor;
