import React, {Suspense, lazy} from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch,  Redirect} from 'react-router-dom';
import "./index.css";
//import * as serviceWorker from "./serviceWorker";
import Header from "./components/header";
import GridForm from "./modules/diag/lgrid";

/*
const App = () => (
  <Router>
    <Header/>
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
      <ListPages />
</Switch>
</Suspense>
</Router>
);
*/

function checkUid()
  {
    if (sessionStorage.getItem("uid")) 
      return true
   sessionStorage.setItem("rd", document.location)

    return false;

  }



const PrivateRoute = ({ component: Component, ...rest }) => 
(
 <Route {...rest} render={(props) => (

	  checkUid()	? <Component {...props} />
			: <Redirect to='/login' />
  )
} />
)

const Diagrams = () => (

  <div><GridForm/></div>

)





const Home = () => (

  <div>PTS2</div>

)

const App = () => (
  <Router>
    <Header/>
{/* note -- the following is NOT a menu.  Look in Header & Menu for the menu!!! */}    
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        <Route path="/login" component={require("./components/login").default}/>
        <Route path="/reset1" component={require("./reset1").default}/>
        <Route path="/reset2" component={require("./reset2").default}/>

        <PrivateRoute exact path="/" component={Home}/>
        <PrivateRoute path="/admin/projects" component={lazy(() => import("./components/list-projects"))}/>
        <PrivateRoute path="/admin/ust/" component={lazy(() => import("./components/list-ust"))}/>
        <PrivateRoute path="/admin/users" component={lazy(() => import("./components/list-users"))}/>
        <PrivateRoute path="/admin/net/" component={lazy(() => import("./admin/list_net"))}/>
        <PrivateRoute path="/admin/wikis" component={lazy(() => import("./admin/form_wikis"))}  />
        <PrivateRoute  path="/admin/wedit" component={lazy(() => import("./admin/form_wedit"))}  />
        <PrivateRoute  path="/admin/media" component={lazy(() => import("./admin/list_media"))}  />
{/*        <PrivateRoute  path="/admin/locks" component={lazy(() => import("./admin/list_locks"))}  /> */}
        <PrivateRoute path="/admin/devices" component={lazy(() => import("./admin/list_devices"))}/>
        <PrivateRoute path="/admin/form_device" component={lazy(() => import("./admin/form_device"))}/>
        <PrivateRoute path="/admin/policies" component={lazy(() => import("./admin/list_policies"))}/>
        <PrivateRoute path="/admin/policy_lines" component={lazy(() => import("./admin/list_policy_lines"))}/>

        <PrivateRoute path="/user/pages" component={lazy(() => import("./user/list_pages"))}/>
        <PrivateRoute path="/user/diags" component={Diagrams}/>
        <PrivateRoute path="/user/permits" component={lazy(() => import("./user/list_permits"))}/>
        <PrivateRoute path="/user/help" component={lazy(() => import("./user/wiki"))}/>
        <PrivateRoute path="/user/drive_activity" component={lazy(() => import("./user/drive_activity"))}/>
        <PrivateRoute path="/user/list_activity" component={lazy(() => import("./user/list_activity"))}/>


        <PrivateRoute path="/acc/trial" component={lazy(() => import("./acc/list_trial"))}/>
        <PrivateRoute path="/acc/pl" component={lazy(() => import("./acc/list_pl"))}/>
        <PrivateRoute path="/acc/bal" component={lazy(() => import("./acc/list_bal"))}/>
        <PrivateRoute path="/acc/reports" component={lazy(() => import("./acc/list_reports"))}/>
        <PrivateRoute path="/acc/time_listing" component={lazy(() => import("./acc/list_tlr"))}/>
        <PrivateRoute path="/acc/ust_invoices" component={lazy(() => import("./acc/list_ust_invoices"))}/>
        <PrivateRoute path="/acc/inv_lines" component={lazy(() => import("./acc/list_inv_lines"))}/>
        <PrivateRoute path="/acc/ust_lines" component={lazy(() => import("./acc/list_ust_lines"))}/>

        <PrivateRoute path="/ust/ajera" component={lazy(() => import("./acc/list_ust"))}/>


      </Switch>
    </Suspense>
  </Router>
);


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);




/*
document.title = "PTS";
if (document.location.pathname == "/admin/projects") {
  ReactDOM.render(<ListProjects />, document.getElementById("root"));
} else if(document.location.pathname.startsWith("/admin/ust/")){
  ReactDOM.render(<ListUST />, document.getElementById("root"));
} else if (document.location.pathname.startsWith("/admin/users")) {
  ReactDOM.render(<ListUsers />, document.getElementById("root"));
} else if (document.location.pathname == "/user/pages") {
  ReactDOM.render(<App />, document.getElementById("root"));
} else if (document.location.pathname == "/rpass1") {

  ReactDOM.render(<ResetPass1 />, document.getElementById("root"));
} else if (document.location.pathname == "/rpass2") {
  ReactDOM.render(<ResetPass2 location={document.location} />, document.getElementById("root"));
} else if (document.location.pathname == "/login") {
  ReactDOM.render(<Login />, document.getElementById("root"));
} else {
  ReactDOM.render(<Header />, document.getElementById("root"));
}
*/

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();
