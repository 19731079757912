import React, { Component } from "react";

/*
function checkLoggedIn() {
  if (!sessionStorage.getItem("uid")) {
    document.location = "/login";
  }
}
*/

class Menu extends Component {
  componentDidMount() {
//    checkLoggedIn();
    //    document.getElementById("year").value = sessionStorage.getItem("year");
    //    console.log(sessionStorage.getItem("year"));
  }

  doLogout() {
    // Clears all local storage
    sessionStorage.clear();
    document.location = "/login";
  }

  render() {
    let admin = "";
    let acc = "";
    let pm = "";
    let ust;
    let user;
    let logout;
    let info;

    if (sessionStorage.getItem("uid"))
    {
      user =  (<><a href="/" className="w3-bar-item w3-button">
      Home
    </a>
    </>)

    logout = <><a
    href="#"
    onClick={this.doLogout.bind(this)}
    className="w3-bar-item w3-button"
  >
    Logout
  </a></>

    }

    if (sessionStorage.getItem("admin")) {
      admin = (
        <div className="w3-dropdown-hover">
          <button className="w3-button">Admin</button>
          <div className="w3-dropdown-content w3-bar-block w3-border">
            <a href="/admin/net" className="w3-bar-item w3-button">
              Network Issues
            </a>
            <a href="/admin/wikis" className="w3-bar-item w3-button">
              Wikis
            </a>
            <a href="/admin/wikis?pid=2" className="w3-bar-item w3-button">
              Admin Wikis
          </a>
          <a href="/admin/media" className="w3-bar-item w3-button">
      Media
      </a>

            <a href="/admin/users" className="w3-bar-item w3-button">
              Users
            </a>
          </div>
        </div>
      );
    }
    if (sessionStorage.getItem("admin") || sessionStorage.getItem("ust") || sessionStorage.getItem("acc")) {
      ust = (
        <div className="w3-dropdown-hover">
          <button className="w3-button">UST</button>
          <div className="w3-dropdown-content w3-bar-block w3-border">
            <a href="/acc/time_listing" className="w3-bar-item w3-button">
              PTS Time Listing Data
            </a>
            <a href="/acc/ust_invoices" className="w3-bar-item w3-button">
              PTS UST Invoices
            </a>
            <a href="/ust/ajera?id=101" className="w3-bar-item w3-button">
              Ajera Data
            </a>
          </div>
        </div>
      );
    }
    if (sessionStorage.getItem("admin"))
    {
    info = <div className="w3-dropdown-hover">
    <button className="w3-button">Information</button>
    <div className="w3-dropdown-content w3-bar-block w3-border">
    <a href="/user/list_activity" className="w3-bar-item w3-button">
      Drive Activity
      </a>
    <a href="/user/help" className="w3-bar-item w3-button">
      Help
      </a>
      <a href="/user/issues" className="w3-bar-item w3-button">
      Issues
      </a>
      <a href="/admin/policies" className="w3-bar-item w3-button">
      Policies
      </a>
      <a href="/admin/devices" className="w3-bar-item w3-button">
      Devices
      </a>
      <a href="/user/software" className="w3-bar-item w3-button">
      Software
      </a>
      <a href="/user/todo" className="w3-bar-item w3-button">
      Todo List
      </a>
      <a href="/user/purchasing" className="w3-bar-item w3-button">
      Purchasing List
      </a>
      <a href="/user/discussion" className="w3-bar-item w3-button">
      Discussions
      </a>
      <a href="/user/diags" className="w3-bar-item w3-button">
      Diagrams
      </a>
      </div>
      </div>
    }
    if (sessionStorage.getItem("admin") || sessionStorage.getItem("acc")) 
    {
      acc = (
        <div className="w3-dropdown-hover">
          <button className="w3-button">Accounting</button>
          <div className="w3-dropdown-content w3-bar-block w3-border">
          <a href="/user/projects" className="w3-bar-item w3-button">
              Project List
            </a>

            <a href="/acc/ust" className="w3-bar-item w3-button">
              UST
            </a>
            <a href="/acc/eprofit" className="w3-bar-item w3-button">
              Employee Profitability
            </a>
            <a href="/acc/trial" className="w3-bar-item w3-button">
              Trial Balance
            </a>
            <a href="/acc/bal" className="w3-bar-item w3-button">
              Balance Sheet
            </a>
            <a href="/acc/pl" className="w3-bar-item w3-button">
              Profit & Loss Statement
            </a>
            <a href="/acc/reports" className="w3-bar-item w3-button">
              Reports
            </a>
          </div>
        </div>
      );
    }
    if (sessionStorage.getItem("admin") || sessionStorage.getItem("pm")) 
    {
      pm = (
        <div className="w3-dropdown-hover">
          <button className="w3-button">Items</button>
          <div className="w3-dropdown-content w3-bar-block w3-border">
            <a href="/user/permits" className="w3-bar-item w3-button">
              Permits
            </a>
            <a href="#" className="w3-bar-item w3-button">
      Help Topics
    </a>
    <a href="#" className="w3-bar-item w3-button">
      Report an Issue
    </a>
    <a href="/user/pages" className="w3-bar-item w3-button">
      Website
    </a>

          </div>

        </div>
      );
    }

    return (
      <div className="w3-bar w3-black">
        {user}
        {info}
        {pm}
        {ust}
        {acc}
        {admin}
        {logout}
      </div>
    );
  }
}

export default Menu;
