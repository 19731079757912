import React, { Component } from "react";


class CompSpace extends Component {
      /// OE??!!! Just a space
          render()
          {
              return(
                  <>&nbsp;</>
              )
          }
          }
          
export const Space = CompSpace
  