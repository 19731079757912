/*export const config = {
//  back: 'https://www.vmaud.com/api',
  back: 'https://localhost:3010/api',
  client: true,
}
*/

//export const back = 'https://localhost:3010/api'

export const back = window.location.origin+"/api";
export const client = true


