const React = require("react")
const Component = React.Component
const helper = require("./helper");

class ResetPass extends Component
{

  constructor(props)
  {
    super(props);
    var p = helper.parseQuery(this.props.location.search);
    this.state = {
      password1:null,
      password1:null,
      error:null,
      guid: p.guid,
      showError:""
    };
  }

  doReset()
  {

      if (this.state.password1 != this.state.password2)
      {
        this.setState({showError:"show", error: "Passwords Don't Match"});
//        alert(this.state.password1);
        return;
      }
      if (this.state.password1.length < 8)
      {
        this.setState({showError:"show", error: "Password is too short"});
        return;
      }
    helper.postToServer("/rpass2", {guid: this.state.guid, password: this.state.password1}, true)
    .then(res => {
        this.setState({showError:"show", error: "Your password has been reset. You can now login."});
    
    })
    .catch(err => {
      this.setState({showError:"show", error: err});

    })
  }

  handleInputChange(event) {
    const target = event.target;
    if (target === undefined)
    {
      this.setState({
        date: event
          });
      return
    }
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });

  }

render()
{
    return(
        <div>
    <div class="d-flex flex-column align-items-center mt-5">
    </div>
        <div class="login-box">
            <h2 class="text-center text-warning mb-4">{this.state.error}</h2>
        

            <div class="form-group">
              <label for="newPassword"><i class="fa fa-lock text-warning" aria-hidden="true"></i> New Password</label>
              <input type="password" onChange={this.handleInputChange.bind(this)} class="form-control" name="password1" value={this.state.password1} placeholder="Enter New Password"/>
            </div>
            <div class="form-group">
              <label for="confirmPassword"><i class="fa fa-lock text-warning" aria-hidden="true"></i> Confirm Password</label>
              <input type="password" onChange={this.handleInputChange.bind(this)} class="form-control" name="password2" value={this.state.password2} placeholder="Confirm New Password"/>
            </div>
            <button onClick={this.doReset.bind(this)} class="btn btn-warning btn-lg login-btn">Reset My Password</button>
      </div>
    <div class="login-footer">
    </div>

    </div>
)

}    
}

export default ResetPass;
